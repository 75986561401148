(function ($) {

    /*
     *  new_map
     *
     *  This function will render a Google Map onto the selected jQuery element
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$el (jQuery element)
     *  @return	n/a
     */

    function hide_all_infowindows(map) {
        map.markers.forEach(function (marker) {
            marker.infowindow.close(map, marker);
        });
    }

    function new_map($el) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom: 14,
            center: new google.maps.LatLng(51.91943, 19.145136),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{
                    "elementType": "geometry", "stylers": [{
                            "color": "#f5f5f5"
                        }
                    ]
                }
                , {
                    "elementType": "labels.icon", "stylers": [{
                            "visibility": "off"
                        }
                    ]
                }
                , {
                    "elementType": "labels.text.fill", "stylers": [{
                            "color": "#616161"
                        }
                    ]
                }
                , {
                    "elementType": "labels.text.stroke", "stylers": [{
                            "color": "#f5f5f5"
                        }
                    ]
                }
                , {
                    "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{
                            "color": "#bdbdbd"
                        }
                    ]
                }
                , {
                    "featureType": "poi", "elementType": "geometry", "stylers": [{
                            "color": "#eeeeee"
                        }
                    ]
                }
                , {
                    "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{
                            "color": "#757575"
                        }
                    ]
                }
                , {
                    "featureType": "poi.park", "elementType": "geometry", "stylers": [{
                            "color": "#e5e5e5"
                        }
                    ]
                }
                , {
                    "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{
                            "color": "#9e9e9e"
                        }
                    ]
                }
                , {
                    "featureType": "road", "elementType": "geometry", "stylers": [{
                            "color": "#ffffff"
                        }
                    ]
                }
                , {
                    "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{
                            "color": "#757575"
                        }
                    ]
                }
                , {
                    "featureType": "road.highway", "elementType": "geometry", "stylers": [{
                            "color": "#dadada"
                        }
                    ]
                }
                , {
                    "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{
                            "color": "#616161"
                        }
                    ]
                }
                , {
                    "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{
                            "color": "#9e9e9e"
                        }
                    ]
                }
                , {
                    "featureType": "transit.line", "elementType": "geometry", "stylers": [{
                            "color": "#e5e5e5"
                        }
                    ]
                }
                , {
                    "featureType": "transit.station", "elementType": "geometry", "stylers": [{
                            "color": "#eeeeee"
                        }
                    ]
                }
                , {
                    "featureType": "water", "elementType": "geometry", "stylers": [{
                            "color": "#c9c9c9"
                        }
                    ]
                }
                , {
                    "featureType": "water", "elementType": "labels.text.fill", "stylers": [{
                            "color": "#9e9e9e"
                        }
                    ]
                }
            ],
            scrollwheel: false,
        };


        // create map
        var map = new google.maps.Map($el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function () {

            add_marker($(this), map);

        });


        // center map
        center_map(map);


        // return
        return map;

    }

    /*
     *  add_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    function add_marker($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
        });

        var icon_url = $marker.attr('data-icon');

        if (icon_url === undefined) {
            icon_url = "marker";
        }

        var icon = {url: acfmaps.imgdir + icon_url + ".svg"};
        marker.setIcon(icon);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html())
        {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {

                hide_all_infowindows(map);
                infowindow.open(map, marker);

            });

            marker.infowindow = infowindow;

        }

        // add to array
        map.markers.push(marker);

    }

    function add_marker_ajax(marker_data, map) {

        // var
        var latlng = new google.maps.LatLng(marker_data.lat, marker_data.lng);

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
        });

        var icon_url = marker_data.icon;

        if (icon_url === undefined) {
            icon_url = "marker";
        }

        var icon = {url: acfmaps.imgdir + icon_url + ".svg"};
        marker.setIcon(icon);

        // if marker contains HTML, add it to an infoWindow
        if (marker_data.content)
        {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content: marker_data.content
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {

                hide_all_infowindows(map);
                infowindow.open(map, marker);

            });

            marker.infowindow = infowindow;

        }

        // add to array
        map.markers.push(marker);

    }

    /*
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    function center_map(map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        } else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    }


    function delete_markers(map) {
        for (var i = 0; i < map.markers.length; i++) {
            map.markers[i].setMap(null);
        }
        map.markers = [];
    }


    function update_markers() {

        var ajax_data = {
            action: "map_markers",
            search_text: search_text,
        };

        $.ajax({
            type: "POST",
            dataType: "json",
            url: acfmaps.ajaxurl,
            data: ajax_data,
            success: function (data) {
                delete_markers(map);

                $(".map__search__alert").hide();

                for (var i = 0; i < data.length; i++) {
                    add_marker_ajax(data[i], map);
                }

                if (data.length == 0) {
                    $(".map__search__alert").removeClass("success").addClass("warning").html(acfmaps.msg_notfound).slideToggle();
                } else {
                    $(".map__search__alert").removeClass("warning").addClass("success").html(acfmaps.msg_found.format(data.length)).slideToggle();
                    center_map(map);
                }

                for (var i = 0; i < data.length; i++) {
                    add_marker_ajax(data[i], map);
                }
            }

        });
    }

    function search_markers() {
        search_text = $("#map-search-text").val();
        update_markers();
    }


    /*
     *  document ready
     *
     *  This function will render each map when the document is ready (page has loaded)
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	5.0.0
     *
     *  @param	n/a
     *  @return	n/a
     */
// global var
    var map = null;
    var search_text = null;

    $(document).ready(function () {

        String.prototype.format = function () {
            var formatted = this;
            for (arg in arguments) {
                formatted = formatted.replace("{" + arg + "}", arguments[arg]);
            }
            return formatted;
        };

        $('.google-map').each(function () {

            // create map
            map = new_map($(this));

        });

        $('#map-search-text').keypress(function (event) {
            if (event.which === 13) {
                search_text = $(this).val();
                search_markers();
            }
        });

        $('#map-search-submit').click(function (event) {
            event.preventDefault();
            search_markers();
        });

    });

})(jQuery);
